import './App.css';
import moment from 'moment';

function ClaimText({claim, rating, sourceName, sourceUrl, date}) {
    if (rating === 'true') {
        return (
            <div className='claim-text'>
                <p>{claim + '? '}<b className='t'>{rating}!</b> </p>
                <p className="source-url">source:&nbsp;{<a href={sourceUrl}>{sourceName}</a>}</p>
                <p className='date'>{date}</p>
            </div>
        )
    }
    return (
        <div className='claim-text'>
            <p>{claim + '? '}<b className='f'>{rating}!</b> </p>
            <p className="source-url">source:&nbsp;{<a href={sourceUrl}>{sourceName}</a>}</p>
            <p className='date'>{date}</p>
        </div>
    );
}

export default function ClaimCard(props) {
    console.log(props)
    let convertedDate = moment(props.claim.claimDate)
    return (
        <div className="card">
            <ClaimText
                claim={props.claim.text}
                rating={props.claim.claimReview[0].textualRating}
                sourceName={props.claim.claimReview[0].publisher.name}
                sourceUrl={props.claim.claimReview[0].url}
                date={convertedDate.format('LL')}
            />
            <hr></hr>
        </div>
    );
}
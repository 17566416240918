import { useNavigate } from 'react-router-dom';
import './App.css';

export default function Navbar() {
    const navigate = useNavigate();
    const onClickHome = () => {
        navigate('/')
    }
    return (
        <div className="navbar">
        <header >
            <button className='logo' onClick={onClickHome}>nobull.</button>
        </header>
        <hr></hr>
        </div>
    );
}
import './App.css';
import UrlInput from './UrlInput.js';
import Navbar from './Navbar';

export const Home = () => {
  return (
    <div className='landing-page'>
      <Navbar/>
      <div className='body'>
        <div className='row'>
          <div className='col'>
            <p>
              <span className='norm'>no&nbsp;</span> <span className='norm'>misinformation. <br></br></span>
              <span className='norm'>no&nbsp;</span> <span className='accent'>red flags.<br></br></span>
              <span className='accent'>no&nbsp;</span> <span className='accent'>bull.<br></br></span>
              <span className='text'>we have the noble mission of helping you fact check tiktoks, videos, news articles, and more!</span>
              <UrlInput className='url'></UrlInput>
            </p> 
          </div>
        </div>
        <div>
          <img src={require('./bull.png')} className='bull' alt="bull stomping on red flag"/>
        </div>
      </div>
    </div>
  );
}

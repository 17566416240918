import './App.css';
import ClaimCard from './ClaimCard';

export const Infobox = (props) => {

    console.log(props.data[0]);
    const res = []
    const seenTitles = new Set();
    for (let openaiClaim in props.data[0]) {
        let real = props.data[0][openaiClaim];
        console.log(real)
        for (let factCheckClaim in real.factCheckClaims) {
            console.log(real.factCheckClaims[factCheckClaim])
            if (!seenTitles.has(real.factCheckClaims[factCheckClaim].claimReview[0].url)) {
                res.push(real.factCheckClaims[factCheckClaim])
                seenTitles.add(real.factCheckClaims[factCheckClaim].claimReview[0].url)
            }
        }
    }
    console.log(res)

    return (
        <div className="info-box">
        <div className="info-box-section">
            <h2>claims</h2>
            <hr></hr>
            {res.map((item) => (
                <ClaimCard claim={item}/>
            ))}
        </div>
    </div>
    );
}
export default Infobox;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgHGuYLJxXcY44c-mVUylRE_zccO_S644",
  authDomain: "nobull-dubhacks.firebaseapp.com",
  projectId: "nobull-dubhacks",
  storageBucket: "nobull-dubhacks.appspot.com",
  messagingSenderId: "460948307230",
  appId: "1:460948307230:web:9d840f5fd4b005c4dc9d0f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);
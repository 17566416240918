import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Home} from './Home';
import {Fax} from './Fax';

export const Paths = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' Component={Home}/>
        <Route exact path='/info/:id' Component={Fax}/>
      </Routes>
    </Router>
  )
}

import './App.css';
import Navbar from './Navbar';
import Infobox from './Infobox';
import Loading from './Loading';
import { useParams } from 'react-router-dom';
import { functions } from './firebase';
import { httpsCallable } from "firebase/functions";
import { useState } from 'react';

const getClaimsForTikTokUrl = httpsCallable(functions, 'getclaimsfortiktokurl');

export const Fax = () => {
    const routeParams = useParams();
    const videoID = routeParams.id;
    const [claimsData, setClaimsData] = useState();

    if (!claimsData) {
      getClaimsForTikTokUrl({videoID: videoID}).then((result) => {
        setClaimsData(result.data);
        console.info(result.data);
      })
      return (
        <Loading />
      )
    }

    return (
        <div className="info-page">
            <Navbar/>
            <div className='row'>
                <div className='col'>
                  <div className='under'>
                    <div className='together'>
                      <h1 className="bullheading">bull?&nbsp;</h1> <h1 className="verdict">red flag. ¡olé!</h1>
                    </div>
                    <div className='together'>
                      <iframe className='tiktok' src={`https://www.tiktok.com/embed/${videoID}`}></iframe>
                      {claimsData && <Infobox data={claimsData}></Infobox>}
                    </div>
                  </div>
                </div>
            </div>
        </div>
    );
    }

    export default Fax;
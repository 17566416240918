import './App.css';
import Navbar from './Navbar';

export default function Loading() {
  return (
    <div>
      <Navbar/>
      <div className='row'>
          <div className='col'>
            <div className='under'>
              <img src={require('./bullgif.gif')} alt="loading..." />
              <h1 className='load'>loading....</h1>
            </div>
          </div>
        </div>
    </div>
    
  )
}
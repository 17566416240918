import React from "react";
import './UrlInput.css';
import { NavLink, useNavigate } from "react-router-dom";

function getVideoID(url) {
    let startIdx = url.indexOf("/video/");
    if (startIdx === -1) return "";
    let id =  url.substring(startIdx + 7, url.length - 1);
    if (id.length > 19) {
        return id.substring(0, id.indexOf("?"));
    }
    return id;
}

function UrlInput() {
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation();
            let url = document.getElementById("meowput").value;
            console.log(url);
            document.getElementById("meowput").value = "";
            let videoID = getVideoID(url);
            navigate(`/info/${videoID}`);
        }
    }
    return (
        <div>
            <input type="text" id="meowput" name="fname" placeholder="paste your link here !" onKeyDown={handleSubmit}></input>
            <br/>
            <NavLink id="demolink" to="/info/7247961562642697518">or click here to try a demo</NavLink>
        </div>
    )

}
export default UrlInput;
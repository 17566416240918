import {Paths} from './Paths';
// import './App.css';

function App() {
  return (
    <Paths />
  );
}

export default App;
